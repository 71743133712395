<template>
<div class="password-reset">
  <div class="content">
    <div class="panel">
      <password-reset-error v-if="error"/>
      <password-reset-success v-if="success"/>
      <password-reset-form v-if="!error && !success"
                           :token="token"
                           @success="success = true"
                           @error="error = true"/>
    </div>
  </div>
</div>
</template>

<script>
import PasswordResetForm from './PasswordResetForm'
import PasswordResetSuccess from './PasswordResetSuccess'
import PasswordResetError from './PasswordResetError'

export default {
  components: {
    PasswordResetForm,
    PasswordResetSuccess,
    PasswordResetError
  },

  props: {
    token: {
      required: true,
      type: String
    }
  },

  data() {
    return {
      success: false,
      error: false
    }
  },

  metaInfo() {
    return {
      title: this.$t('auth.passwordReset.pageTitle')
    }
  }
}
</script>

<style lang="scss" scoped>
.password-reset {
  padding-top: 40px;
  background-color: #f8f8f8;

  .content {
    display: flex;
    justify-content: center;
    padding: 20px;
  }

  .panel {
    width: 500px;
    padding: 40px;

    z-index: map-get($zIndexes, panel);

    box-shadow: 0 6px 12px 0 rgba(54,76,106,0.20);
    border-radius: 8px;
  }

  /deep/ {
    .title {
      font-size: 26px;
      font-weight: bold;
      color: $primary-color;
      letter-spacing: -0.35px;
      text-align: center;
      line-height: 38px;
      margin-bottom: 20px;
    }

    .message {
      font-size: 14px;
      color: #797979;
      margin-bottom: 30px;
      text-align: center;

      &:last-child {
        margin: 0;
      }
    }
  }
}
</style>
