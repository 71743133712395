<template>
  <div class="password-reset-form">
    <div class="title">
      {{ $t('auth.passwordReset.form.title') }}
    </div>

    <div class="message">
      {{ $t('auth.passwordReset.form.message') }}
    </div>

    <ValidationObserver ref="observer" slim>
      <base-text-box :type="showPassword ? 'text': 'password'"
                     name="password"
                     v-model="formData.credentials.password"
                     :show-password-visibility-button="true"
                     :show-password="showPassword"
                     rules="required|min:6"
                     :rounded="true"
                     :placeholder="$t('auth.passwordReset.form.passwordPlaceholder')"
                     @passwordVisibilityButtonClick="showPassword = !showPassword"/>

      <base-button class="full-width"
                   :loading="loading"
                   @click="submit">
        {{ $t('auth.passwordReset.form.submit') }}
      </base-button>
    </ValidationObserver>
  </div>
</template>

<script>
import { restore } from '@/services/auth'

export default {
  props: {
    token: {
      required: true,
      type: String
    }
  },

  data() {
    return {
      formData: {
        engine: 'email',
        credentials: {
          restore_code: this.token,
          password: ''
        }
      },

      showPassword: false,
      loading: false
    }
  },

  methods: {
    async submit () {
      const valid = await this.$refs.observer.validate()

      if (!valid) {
        return false
      }

      if (this.loading) {
        return
      }

      this.loading = true

      try {
        await restore(this.formData)
        this.$emit('success')
      } catch (e) {
        this.$emit('error')
      }

      this.loading = false
    }
  }
}
</script>
