<template>
  <div class="password-reset-success">
    <div class="title">
      {{ $t('auth.passwordReset.success.title') }}
    </div>

    <div class="message">
      {{ $t('auth.passwordReset.success.message') }}
    </div>

    <!-- <base-button class="full-width"
                 @click="login">
      {{ $t('auth.passwordReset.success.loginButton') }}
    </base-button> -->
  </div>
</template>

<script>
import bus from '@/bus'

export default {
  methods: {
    login() {
      bus.$emit('loginRequested')
    }
  }
}
</script>
