<template>
  <div class="password-reset-error">
    <div class="title">
      {{ $t('auth.passwordReset.error.title') }}
    </div>

    <div class="message">
      {{ $t('auth.passwordReset.error.message') }}
    </div>

    <!--<base-button class="full-width"
                @click="login">
      {{ $t('auth.passwordReset.error.loginButton') }}
    </base-button>-->
  </div>
</template>

<script>
import bus from '@/bus'

export default {
  methods: {
    login() {
      bus.$emit('loginRequested')
    }
  }
}
</script>
